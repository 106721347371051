import logo from '../assets/logo.png'

const Header = () => {

    return (
        <>
            <img src={logo} alt="logo" style={{height: 80, marginBottom: 0}} />
        </>
    )
}

export default Header