export const drawRect = (detections, ctx, objects, imageWidth, imageHeight, handleNewText) => {
  ctx.clearRect(0, 0, imageWidth, imageHeight); // Clear the canvas before drawing

  detections.forEach((detection) => {
    const text = detection.Name;

    if (detection.Instances && detection.Instances.length > 0) {
      detection.Instances.forEach((instance) => {
        // Extract bounding box and label
        const bbox = instance.BoundingBox;

        // Convert bounding box from percentage to pixels
        const x = bbox.Left * imageWidth;
        const y = bbox.Top * imageHeight;
        const width = bbox.Width * imageWidth;
        const height = bbox.Height * imageHeight;

        console.log(text, x, y);

        // Set styling
        const color = Math.floor(Math.random() * 16777215).toString(16);
        ctx.strokeStyle = "#" + color;
        ctx.font = "18px Arial";

        // Draw rectangles and text
        ctx.beginPath();
        ctx.fillStyle = "#" + color;
        ctx.fillText(text, x, y);
        ctx.rect(x, y, width, height);
        ctx.stroke();
      });

      // Trigger the new text handler
      if (!objects.some(i => i === text)) {
        handleNewText(text);
      }
    }
  });
};
