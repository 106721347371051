// aws-config.js
import AWS from 'aws-sdk';

// Configure AWS SDK
AWS.config.update({
  region: 'us-east-1',
  accessKeyId: 'AKIAQE43JWOPDJMLPY4K',
  secretAccessKey: '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'
});

const rekognition = new AWS.Rekognition();
const polly = new AWS.Polly();

const aws = {
    rekognition, polly
}

export default aws
