import './App.css';
import React, { useRef, useState, useEffect } from 'react';
import Header from './components/Header';
import AudioPlay from './assets/audio-play.svg';
import AudioStop from './assets/audio-stop.svg';
import BackCamera from './assets/back-camera.svg';
import FrontCamera from './assets/front-camera.svg';
import EN from './assets/EN.svg';
import Webcam from 'react-webcam';
import awsServices from './aws-config';
import { Buffer } from 'buffer';
import { drawRect } from './utilities';
const { rekognition, polly } = awsServices;

const App = () => {

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const audioRef = useRef();
  const objects = useRef([]);
  const [camera, setCamera] = useState('user'); // 'user' for front camera, 'environment' for back camera
  const [isPlay, setIsPlay] = useState(true);

  const handleNewText = (newText) => {
      if (!objects.current.some(i => i === newText)) {
        objects.current.push(newText);
        convertTextToSpeech(newText);
      }
  };

  const convertTextToSpeech = (newText) => {
      polly.synthesizeSpeech({
          Text: newText,
          OutputFormat: 'mp3',
          VoiceId: 'Salli'
      }, (error, data) => {
          if (error) {
              console.error('Something went wrong!');
          } else {
              const audio = audioRef.current;

              if (data) {
                  const audioArrayBuffer = data.AudioStream.buffer;
                  const audioURL = URL.createObjectURL(new Blob([audioArrayBuffer], {type: "audio/mpeg"}));

                  audio.src = audioURL;
                  audio.oncanplaythrough = () => {
                      if (isPlay) {
                          audio.play().catch(err => console.error('Error playing audio:', err));
                      }
                  };

                  // Trigger loading of the audio
                  audio.load();

                  return () => {
                      URL.revokeObjectURL(audioURL);
                  };
              }
          }
      });
  }

  const videoConstraints = {
    facingMode: camera
  };

  const takeSnapshot = () => {
    if (webcamRef.current && canvasRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const imageData = imageSrc.split(',')[1]; // Remove base64 header
        detectObjects(imageData);
      }
    }
  };

  const detectObjects = (imageData) => {
    const params = {
      Image: {
        Bytes: Buffer.from(imageData, 'base64')
      },
      MaxLabels: 10,
      MinConfidence: 70,
    };

    rekognition.detectLabels(params, (err, data) => {
      if (err) {
        console.error(err, err.stack);
      } else {
        const context = canvasRef.current.getContext('2d');
        drawRect(data.Labels, context, objects.current, canvasRef.current.width,
            canvasRef.current.height, handleNewText);
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(takeSnapshot, 2000);
    return () => clearInterval(interval);
  });

  const toggleItem = (value) => {
    if (value === 'camera') {
        setCamera((camera === 'user') ? 'environment' : 'user');
    } else {
        const audio = audioRef.current;
        isPlay ? audio.pause() : audio.play();
        setIsPlay(!isPlay);
    }
  }

  return (
    <>
      <div className="container-header">
          <Header />
          <Webcam ref={webcamRef} screenshotFormat="image/jpeg" videoConstraints={videoConstraints}
            muted={true} width={500} height={400}
            style={{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              textAlign: "center",
              top: "100px",
            }}
          />

          <canvas ref={canvasRef} width={500} height={400}
            style={{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              textAlign: "center",
              top: "100px",
            }}
          />
      </div>
      <audio ref={audioRef} />
      <div className="audio-container">
        <button className="audio-button" onClick={() => toggleItem('audio')}>
            {isPlay ?
                <img src={AudioPlay} alt={AudioPlay} className="icon-btn"/>
                :
                <img src={AudioStop} alt={AudioPlay} className="icon-btn"/>
            }
        </button>
        <button className="audio-button">
            <img src={EN} alt={EN} className="icon-btn"/>
        </button>
        <button className="audio-button" onClick={() => toggleItem('camera')}>
            {camera === 'user' ?
                <img src={FrontCamera} alt={FrontCamera} className="icon-btn"/>
                :
                <img src={BackCamera} alt={BackCamera} className="icon-btn"/>
            }
        </button>
      </div>
    </>
  );
};

export default App;
